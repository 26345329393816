import { useState } from 'react';
import Card from 'react-bootstrap/Card'
import { CardGroup } from 'react-bootstrap';
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBFooter } from "mdbreact";
import ImageGallery from 'react-image-gallery';

import '../css/about.css';
import '../css/vision.css';
import about1 from '../images/about1.jpg'
import about3 from '../images/about3.jpeg'

import team1 from '../images/team1.jpeg'
import team2 from '../images/team22.png'

import avatar from '../images/avatar.png'
import favatar from '../images/favatar.png'

import internet from '../images/internet.png'
import cctv from '../images/cctv.jpeg'
import networking from '../images/network.png'

import missionpic from '../images/missionpic.jpeg'
import partner from '../images/partner.png'
import vision from '../images/vision.png'

import gal1 from '../images/gallery1.webp'
import gal2 from '../images/gallery2.jpg'
import gal3 from '../images/gallery3.jpeg'
import gal4 from '../images/gallery4.jpeg'
import gal5 from '../images/gallery5.jpeg'
import gal6 from '../images/gallery6.jpg'
import gal7 from '../images/gallery7.jpg'

function About() {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = e => {
    e.preventDefault();
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  const images = [
    {
      original: gal6,
      thumbnail: gal6,
    },
    {
      original: gal7,
      thumbnail: gal7,
    },
    {
      original: gal1,
      thumbnail: gal1,
    },
    {
      original: gal2,
      thumbnail:gal2,
    },
    {
      original: gal3,
      thumbnail:gal3,
    },
    {
      original: gal4,
      thumbnail:gal4,
    },
    {
      original: gal5,
      thumbnail:gal5,
    },
  ];

  return (
      <div className='container about-container' id='about'>
          <div className='about-top'>
            <h2>About Us</h2>
            <p>We offer the best network services and products.</p>
          </div>

          <div>
            <CardGroup className='cards'>
                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={about1} className='w-100' />
                    <Card.Body>
                    <Card.Title>WHO WE ARE</Card.Title>
                    <Card.Text>
                    Jambla Investment is one of the newly upcoming IT system integration offering professional services i.e. networking companies in Kenya that is willing to work with enterprises and companies.Welcome to the home of networking,we offer you great work and ideas.
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={missionpic} className='w-100' />
                    <Card.Body>
                    <Card.Title>OUR MISSION</Card.Title>
                    <Card.Text>
                    Jambla Investment mission is to enhance the business operation of its clients by developing and implementing premium IT products and services.
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={about3} className='w-100' />
                    <Card.Body>
                    <Card.Title>OUR MERITS</Card.Title>
                    <Card.Text>
                    Unique and creative solutions that meet the clients’ expectations not only by realizing business objective, but particularly by our strict adherence to the ethical principles of public relations.Welcome to Jambla investments where we offer you what you deserve.
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
          </div>

          <div>
          <div className='vision-one' style={{ marginTop: '2rem'}}>
            <div className='vision-one-top'>
                <h2 style={{ marginBottom: '2rem' }}>Need a networking partner?</h2>
                <p>Team work based on the implementation of progressive communications,disciplines Professional quality of services provided by a highly motivated team. Giving us courage and readiness to communicate on behalf of client in crisis.</p>
            </div>
            <img 
                src={gal6}
                className='vision-img'
                alt='Networking partner'
            />
          </div>
          
          <div className='vision-one' style={{ marginTop: '2rem'}}>
            
          <img 
                src={gal7} 
                className='vision-img'
                alt='Networking partner'
            />
            
            <div className='vision-one-top'>
                    <h2 style={{ marginBottom: '2rem' }}>Our Vision</h2>
                    <p>As a private owned business, Jambla Investments envisions to provide the best IT consultancy, hardware deployment, maintenance as well as professional networking services for our customers.</p>
                </div>
             
          </div>
          </div>

          <div style={{ marginTop: '2rem'}}  id='team'>
            <h2 className='text-center'>Our Team</h2>
          <CardGroup className='cards'>
                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={team1} style ={{width: '70%', height: '70%', margin: '1rem auto'}} className='rounded-circle' />
                    <Card.Body>
                    <Card.Title className='text-center'>Martha Amondi</Card.Title>
                    <Card.Text className='text-center'>
                      <h5>IT</h5>
                      <p>"Hello, am Martha, and am glad to be a member of this networking company which not only works to give you the best but we do it with all the love."</p>
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={team2} style ={{width: '70%', height: '70%', margin: '1rem auto'}} className='rounded-circle' />
                    <Card.Body>
                    <Card.Title className='text-center'>Mr. Stephine Otieno</Card.Title>
                    <Card.Text className='text-center'>
                      <h5>CEO</h5>
                      <p>"Passion drove us here, and we are glad that you have joined us. As the CEO, I welcome you to Jambla investments, we are going places with you."</p>
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={favatar} style ={{width: '70%', height: '70%', margin: '1rem auto'}} className='rounded-circle' />
                    <Card.Body>
                    <Card.Title className='text-center'>Owour Christine</Card.Title>
                    <Card.Text className='text-center'>
                      <h5>Assistant CEO</h5>
                      <p>"Hello there, our beloved customer, well I hope you are here to get value for your cash because that is exactly what we offer. Feel free to get in touch"</p>
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
      </div>

      <div style={{ marginTop: '2rem'}} id='services'>
            <h2 className='text-center'>Our Services</h2>
          <CardGroup className='cards'>
                <Card style={{ margin: '0.5rem' }}>
                <Card.Img variant="top" src={internet} style ={{width: '70%', height: '70%', margin: '1rem auto'}} />                  
                <Card.Body>
                    <Card.Title className='text-center'>Internet Installation Services.</Card.Title>
                    <Card.Text className='text-center'>
                      <p>Internet is now becoming one of the basic needs to have in this era of 21st century. We are specialized in offering Internet services to make sure no one is left behind. We do installation and maintenance of internet services</p>
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={cctv} style ={{width: '70%', height: '70%', margin: '1rem auto'}}  />
                    <Card.Body>
                    <Card.Title className='text-center'>CCTV Installation services</Card.Title>
                    <Card.Text className='text-center'>
                      <p>Everyone would like to have his/her home or workplace or institution secured 24/7, that can only be done by installation of CCTV. We are specialized in Installation and maintenance of CCTVs.</p>
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ margin: '0.5rem' }}>
                    <Card.Img variant="top" src={networking} style ={{width: '70%', height: '70%', margin: '1rem auto'}} />
                    <Card.Body>
                    <Card.Title className='text-center'>Networking services.</Card.Title>
                    <Card.Text className='text-center'>
                      <p>You want an organized and neat networking in your office, home or institution? We are best company to engage because we have the outstanding skills and specialization required in offering networking services. We offer networking services..</p>
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
      </div>

      <MDBContainer id='testimonials'>
      <section className="team-section text-center my-5">
          <h2 className="h1-responsive font-weight-bold my-5">
            Testimonials
          </h2>
          <p className="dark-grey-text w-responsive mx-auto mb-5">
            We bring hope where there seems to be none.
          </p>

          <MDBRow className="text-center">
            <MDBCol md="4" className="mb-md-0 mb-5">
                  <img
                    src={avatar}
                    alt=""
                    className="rounded-circle z-depth-1 img-fluid mx-auto"
                  />
                
                <h4 className="font-weight-bold dark-grey-text mt-4">
                  Williamson
                </h4>
                <h6 className="font-weight-bold blue-text my-3">
                  Business Man
                </h6>
                <p className="font-weight-normal dark-grey-text">
                  <MDBIcon className="fa fa-quote-left pr-2" />
                  Jambla investments has proved to be the best networking company, this is supported by my very
                  efficient and reliable internet connection. I'm grateful.
                </p>
                <div className="orange-text">
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon far icon="star-half" />
                </div>
            </MDBCol>

            <MDBCol md="4" className="mb-md-0 mb-5">
                  <img
                    src={favatar}
                    alt=""
                    className="rounded-circle z-depth-1 img-fluid mx-auto"
                  />
                
                <h4 className="font-weight-bold dark-grey-text mt-4">
                  Kristina
                </h4>
                <h6 className="font-weight-bold blue-text my-3">
                  Web Designer
                </h6>
                <p className="font-weight-normal dark-grey-text">
                  <MDBIcon className="fa fa-quote-left pr-2" />
                  For the best products, I look for Jambla. I have got my CCTVs installed and I am glad.
                </p>
                <div className="orange-text">
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon far icon="star-half" />
                </div>
            </MDBCol>

            <MDBCol md="4" className="mb-md-0 mb-5">
                  <img
                    src={avatar}
                    alt=""
                    className="rounded-circle z-depth-1 img-fluid mx-auto"
                  />
                
                <h4 className="font-weight-bold dark-grey-text mt-4">
                  Steve Thomas
                </h4>
                <h6 className="font-weight-bold blue-text my-3">
                  Architect
                </h6>
                <p className="font-weight-normal dark-grey-text">
                  <MDBIcon className="fa fa-quote-left pr-2" />
                  For all buildings that need a network, I refer you to Jambla investments for a reliable and satisfying
                  network installation.
                </p>
                <div className="orange-text">
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon icon="star" />
                  <MDBIcon far icon="star-half" />
                </div>
            </MDBCol>
            
          </MDBRow>
        </section>
    </MDBContainer>
    

    <h2 className='text-center' id='pricing'>Pricing</h2>
    <CardGroup>
  <Card className='text-center'>
    <Card.Body>
      <Card.Title className='text-center'><h3>Gold</h3></Card.Title>
      <Card.Text>
        
          <p>30mbps</p>
          <p>Unlimited access</p>
          <p>Super speed</p>
       
        
        <Card.Footer>
          <h6>Kshs.20,000 per Month</h6>
        </Card.Footer>
      </Card.Text>
    </Card.Body>
  </Card>

  <Card className='text-center'>
    <Card.Body>
      <Card.Title className='text-center'><h3>Silver</h3></Card.Title>
      <Card.Text>
        
          <p>15mbps</p>
          <p>Unlimited access</p>
          <p>Super speed</p>
       
        
        <Card.Footer><h6>Kshs.10,000 per Month</h6></Card.Footer>
      </Card.Text>
    </Card.Body>
  </Card><Card className='text-center'>
    <Card.Body>
      <Card.Title className='text-center'><h3>Bronze</h3></Card.Title>
      <Card.Text>
        
          <p>10mbps</p>
          <p>Unlimited access</p>
          <p>Super speed</p>
       
        
        <Card.Footer><h6>Kshs.8,000 per Month</h6></Card.Footer>
      </Card.Text>
    </Card.Body>
  </Card><Card className='text-center'>
    <Card.Body>
      <Card.Title className='text-center'><h3>Platinum</h3></Card.Title>
      <Card.Text>
        
          <p>8mbps</p>
          <p>Dedicated links</p>
          <p>10 to 20 users</p>
          <Card.Footer><h6>Kshs.5,000 per Month</h6></Card.Footer>     
        
      </Card.Text>
    </Card.Body>
  </Card><Card className='text-center'>
    <Card.Body>
      <Card.Title className='text-center'><h3>Economy</h3></Card.Title>
      <Card.Text>
        
          <p>5mbps</p>
          <p>Dedicated links</p>
          <p>5 to 10 users</p>
          <Card.Footer><h6>Kshs.3,000 per Month</h6></Card.Footer>     
      </Card.Text>
    </Card.Body>
  </Card><Card className='text-center'>
    <Card.Body>
      <Card.Title className='text-center'><h3>Basic</h3></Card.Title>
      <Card.Text>
        
          <p>4mbps</p>
          <p>Dedicated links</p>
          <p>4 to 5 users</p>       
          <Card.Footer><h6>Kshs.2,000 per Month</h6></Card.Footer>     
      </Card.Text>
    </Card.Body>
  </Card>
</CardGroup>

      <div style={{ marginTop: '2rem'}} id='gallery'>
        <h2 className='text-center'>Gallery</h2>
        <p className='text-center'>Some of our recent works</p>
        <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} />
      </div>

      <div style={{ marginTop: '2rem'}}>
        
<section class="mb-4">

    <h2 class="h1-responsive font-weight-bold text-center my-4"  id='contact'>Contact us</h2>
    <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
        a matter of hours to help you.</p>

    <div class="row">

        <div class="col-md-9 mb-md-0 mb-5">
            <form id="contact-form">

                <div class="row">

                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input value={name} onChange={e=>setName(e.target.value)} type="text" id="name" name="name" class="form-control" />
                            <label for="name" class="">Your name</label>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input  value={email} onChange={e=>setEmail(e.target.value)} type="text" id="email" name="email" class="form-control" />
                            <label for="email" class="">Your email</label>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="md-form mb-0">
                            <input value={subject} onChange={e=>setSubject(e.target.value)} type="text" id="subject" name="subject" class="form-control" />
                            <label for="subject" class="">Subject</label>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-12">

                        <div class="md-form">
                            <textarea value={message} onChange={e=>setMessage(e.target.value)} type="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                            <label for="message">Your message</label>
                        </div>

                    </div>
                </div>

            </form>

            <div class="text-center text-md-left">
                <span class="btn btn-primary" onClick={e=>handleSubmit(e)}>Send</span>
            </div>
            <div class="status"></div>
        </div>

        <div class="col-md-3 text-center">
            <ul class="list-unstyled mb-0">
                <li><i class="fas fa-map-marker-alt fa-2x"></i>
                    <p>KNUT building along Oginga Odinga street, behind ECO- bank Kisumu.</p>
                </li>

                <li><i class="fas fa-phone mt-4 fa-2x"></i>
                    <p>+254 715 425 605</p>
                </li>

                <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                    <p>info@jambla.co.ke</p>
                </li>
            </ul>
        </div>

    </div>

</section>
      </div>

      <div>
      <MDBFooter style={{ backgroundColor: '#ddd', width: '100%'}} className="font-small pt-4 mt-4">
      <div className="footer-copyright text-center py-2">
        <MDBContainer fluid>
          <span>	&copy; 2022</span>
          <p>Designed and Developed by: <a href="https://api.whatsapp.com/send?phone=254799303126&text=Hello">Janet</a> and <a href="https://api.whatsapp.com/send?phone=254758202697&text=Hello">Kim</a></p>
        </MDBContainer>
      </div>
    </MDBFooter>
      </div>
      </div>
  )
}

export default About;

import Carousel from 'react-bootstrap/Carousel'
import '../css/carousel.css';
import slide1 from '../images/slide-1.jpg'
import slide2 from '../images/slide2.jpg'
import { useState, useLayoutEffect } from 'react'

function Slider() {

  const [controlsAreVisible, setControlsAreVisible] = useState(true);

  useLayoutEffect(() => {
    function updateSize() {
      if(window.innerWidth < 480){
          setControlsAreVisible(false)
      }
      if(window.innerWidth > 480){
        setControlsAreVisible(true)
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Carousel controls={controlsAreVisible} variant="dark" className='carousel' id='home'>
    <Carousel.Item>
      <img
        className="slide"
        src={slide1}
        alt="First slide"
      />
      <Carousel.Caption className='caption' style={{
          background: 'rgba(72, 145, 247, 0.6)',
          borderRadius: '16px',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(5px)',
          border: '1px solid rgba(255, 255, 255, 0.3)',
          width: 'fit-content',
          padding: '.5rem'
      }}>
        <h1 style={{color: '#fff'}}>JAMBLA INVESTMENTS</h1>
        <p style={{ fontWeight: 'bolder', color: '#fff'}}>THE NETWORKING PARTNERS YOU TRUST</p>

        <a href='#about' class="btn btn-primary">About Us</a>
      </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
      <img
        className="slide"
        src={slide2}
        alt="First slide"
      />
      <Carousel.Caption className='caption' style={{
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '16px',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(5px)',
          border: '1px solid rgba(255, 255, 255, 0.3)',
          width: 'fit-content',
          padding: '.5rem'
      }}>
        <h1 className='text-white'>JAMBLA INVESTMENTS</h1>
        <p className='text-white' style={{ fontWeight: 'bolder'}}>THE NETWORKING PARTNERS YOU TRUST</p>

        <a href='#about' class="btn btn-primary">About Us</a>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  )
}

export default Slider;

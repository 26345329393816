import About from "./components/About";
import NavigationBar from "./components/NavigationBar";
import Slider from "./components/Slider";

function App() {
  return (
    <div>
      <NavigationBar />
      <Slider />
      <About />
    </div>
  );
}

export default App;

import { Nav, Container } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar'
import '../css/navbar.css';


function NavigationBar() {
  return (
    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#3076E8'}} variant="dark" fixed="top">
    <Container>
    <Navbar.Brand href="#home">Jambla</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto nav-left">
        <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#about">About</Nav.Link>
        <Nav.Link href="#team">Team</Nav.Link>
        <Nav.Link href="#services">Services</Nav.Link>
        <Nav.Link href="#testimonials">Testimonials</Nav.Link>
        <Nav.Link href="#pricing">Pricing</Nav.Link>
        <Nav.Link href="#gallery">Gallery</Nav.Link>
        <Nav.Link href="#contact">Contact</Nav.Link>
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default NavigationBar;
